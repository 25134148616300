import './App.css';
import sky from '../src/Images/image 18.png';
import frame from '../src/Images/Frame.png';
import fation from '../src/Images/vvv 1.png';
import frame1 from '../src/Images/Frame (1).png';
import programmer from '../src/Images/Rectangle 30.png';
import html from '../src/Images/socialet.png';
import node from '../src/Images/Frame 6475.png';
import ff from '../src/Images/ff.png'
import group from '../src/Images/Group 6475.png';
import linkedin from '../src/Images/Group 6438.png'
import github from '../src/Images/Group 6439.png'
import instagram from '../src/Images/Group 6440.png'
import frame2 from '../src/Images/Frame 6567.png';
import katrori from '../src/Images/katrori.png';
import fation1 from '../src/Images/FATIONI.png';
import group11 from '../src/Images/Group.png';
import frame11 from '../src/Images/frame1.png'
import frame22 from '../src/Images/frame2.png'
import frame3 from '../src/Images/frame3.png';
import mask1 from '../src/Images/1.png';
import mask2 from '../src/Images/2.png';
import mask3 from '../src/Images/3.png';
import mask4 from '../src/Images/4.png';
import eksperience from '../src/Images/eksperienca.png';
import plexet from '../src/Images/plexet.png';
import movie from '../src/Images/movie.png';
import shop from '../src/Images/shop.png';
import reactimg from '../src/Images/react2.png'; 
import container from '../src/Images/CONTAINER.svg';
import { Link } from 'react-router-dom';
import Navbar from './Navbar.js';


function App() {

  return (
    <div className="App">
      <Navbar/>
      
      <div className='group' id='home'>
      
      <div className='left'>
    <p className='hello'>Hello World</p>
    <p className='hello1'>I AM FATION KRASNIQI</p>
    <p className='hello2'>FRONTEND DEVELOPER</p>
    <div className='butonat'>
   <a href="/FationKrasniqi2024cv.pdf" download><button className='resume1'>Download Resume</button> </a>
   <button className='resume' onClick={() => window.location.href = '#portfolio'}>
  Go to Projects
</button>
 </div>
 
     </div>
     <div className='elements'>
     <div className='social'>

      <a  href="https://www.instagram.com/fationn_/?hl=en" target="_blank">    <img className='link' src={linkedin}></img></a>
      <a  href="https://github.com/fationkrasniqi" target="_blank">     <img className='link' src={github}></img> </a>
       <a  href="https://www.linkedin.com/in/fation-krasniqi/" target="_blank">   <img className='link' src={instagram}></img></a>
        </div>
  <div class="loader">
    <span></span>
  </div>

  </div>
        </div> 

     
   
      <div id="about" className='secondpart'>
      <div className='left1'>
        <div className='left1test'>
          <div className="minigroup">
        <h1 className='about' >About me </h1>
        <img src={group11}></img>
        </div>
        {/* <img src={fation}></img> */}
       <h2 className='who'>WHO I AM AND WHAT I DO</h2>
       <p class='hellotext'>Hello, I’m Fation. I’m a React Front-end Developer and an overall web enthusiast experienced in building all aspects of the user experience and user interfaces.I design and develop performant beautiful experiences that make people’s lives simple..</p>
       <button className='resume3' onClick={() => window.location.href = '#contact'
       }>Get in touch</button>
       </div>
       <div className='images'>
       <img  className="images3" src={frame11}></img>
       <img className="images3" src={frame22}></img>
       <img className="images33" src={frame3}></img>
       </div>
      </div>
      <div className='rigth1'>
      <img className='fationi' src={fation1}></img>
      </div>
      </div>
      <div className='thirdpart' id='services'>
        <div className='thirdparttest'>
        <center>
        <h1  className='services'>Services</h1>
        <h2 className='expertise'>My Expertise in Front-End Development</h2>
        </center>
      <div className='boxes'>
      <div className='box1'>
        <div className='boxi'>
       <img className='mask' src={mask1}></img>
       <h3 className='maskh1'>Responsive Development</h3>
       <p className='boxp'>Developing websites that are fully responsive and optimized for various devices, ensuring consistent functionality and appearance on desktops, tablets, and mobile phones.</p>
      </div>
      </div>
      <div className='box1'>
        <div className='boxi'>
       <img  className='mask' src={mask2}></img>
       <h3 className='maskh1'>SPA Development  </h3>
       <p className='boxp'>Building dynamic single-page applications using modern frameworks like React, Angular, or Vue.js, providing a smooth and fast user experience with minimal page reloads.</p>
      </div>
       </div>
      <div className='box1'>
        <div className='boxi'>
       <img className='mask' src={mask3}></img>
       <h3 className='maskh1'>Performance Optimization </h3>
       <p className='boxp'>Enhancing website performance by implementing techniques such as code splitting, lazy loading, and image optimization, resulting in faster load times and a better user experience.</p>
      </div>
      </div>
      <div className='box1'>
        <div className='boxi'>
       <img className='mask' src={mask4}></img>
       <h3 className='maskh1'>UI/UX Design</h3>
       <p className='boxp'>Creating user interfaces and experiences that are visually appealing, intuitive, and user-friendly, ensuring that users have a seamless and engaging interaction with the website.</p>
      </div>
      </div>
      </div>
      </div>
      </div>
      <div className='fourthpart' id='skills'>
      <div className='left1'>
        <div className='left1test'>
          <div className="minigroup">
        <h1 className='about' >Skills </h1>
        <img src={group11}></img>
        </div>
        {/* <img src={fation}></img> */}
       <h2 className='who'>WHAT TECHNOLOGIES I USE</h2>
       <p class='hellotext'>I specialize in developing modern web applications using HTML and CSS for layout and styling, JavaScript and React.js for dynamic interfaces, and Node.js for backend development. Additionally, I use WordPress for content management and Figma for design and prototyping.</p>
       <button className='resume3' onClick={()=> window.location.href="#contact"}>Get in touch</button>
       </div>
     
      </div>
      <div>
        <img  className='htmlpic' src={html}></img>
      </div> 
      </div>
      <div className='thirdpart' id='experience'>
        <div className='thirdparttest'>
        <center>
        <h1  className='services'>EXPERIENCE</h1>
        <h2 className='expertise'>DETAILED WORK EXPERIENCE AND CAREER HIGHLIGHTS</h2>
        </center>
       <center>
       <picture>
        <source media="(max-width: 768px)" srcSet={container} />
        <img className="eksperienca1" src={eksperience} alt="Experience Image" />
    </picture>
       </center>
      </div>
      </div>
      <div className='portfolio' id='portfolio'>
      <center>
        <h1  className='services'>PORTFOLIO</h1>
        <h2 className='expertise'>Featured projects and portfolio highlights</h2>
        </center>
        <center>
      
            <div className='portfolio1'>
        <div className='portfolioleft'>
        <img className='leftresponsiveimage' src={plexet}></img>
        </div>
        <div className='portfolioright'>
          <h1 className='about2'>Plexet</h1>
        <div className='portfolioright1'>
          
        <h2 className='expertise3'>Technologies</h2>
        <img className='reactimg2' src={reactimg}></img>
        </div>
        <p className='hellotext'>I had the opportunity to develop the official website for Plexet, a Swiss company, using React.js. The goal was to create a modern, responsive, and user-friendly site that effectively represents the company's brand and services.
        </p>
        <a href="https://plexet.ch/" target='_blank'>
        <button className='resume33'>Watch Live</button>
        </a>
        </div>
        </div>
        <div className='portfolio1'>
        <div className='portfolioleft'>
        <img className='leftresponsiveimage' src={shop}></img>
        </div>
        <div className='portfolioright'>
          <h1 className='about2'>E-Commerce</h1>
        <div className='portfolioright1'>
          
        <h2 className='expertise3'>Technologies</h2>
        <img className='reactimg2' src={reactimg}></img>
        </div>
        <p className='hellotext'>E-commerce site have the following features: User Authentication (including Google Singin) Secure Payments (including Apple and Google Pay) Email Invoicing Users can interact with products, see their order history, and more!
        </p>
        <a href="https://www.youtube.com/watch?v=hpb_CJJFGBM" target='_blank'>
        <button className='resume33'>Watch Live</button>
        </a>
        </div>
        </div>
        <div className='portfolio1'>
        <div className='portfolioleft'>
        <img className='leftresponsiveimage' src={movie}></img>
        </div>
        <div className='portfolioright'>
          <h1 className='about1'>Movie App</h1>
        <div className='portfolioright1'>
          
        <h2 className='expertise3'>Technologies</h2>
        <img className='reactimg2' src={reactimg}></img>
        </div>
        <p className='hellotext'>Movie App is a ReactJS web application developed to display information about any movie. It's built on top of TMDB API, with a great UX, very functional and a Mobile-First design.

        </p>
        <a href="https://movie.fationkrasniqi.dev/" target='_blank'>
        <button className='resume33'>Watch Live</button>
        </a>
        </div>
        </div>
        </center>
      </div>
      <div className='thirdpart'>
        <div className='thirdparttest' id="contact">
        <center>
        <img className='object' src={group11}></img>

        <h1  className='services1'>HAVE A PROJECT IN MIND?</h1>
        <h2 className='expertise33'>I can make it real for you</h2>
        <button className='resume1' onClick={() => window.location.href = 'mailto:fationkrasniqi777@gmail.com'}>Let’s Talk</button>

        </center>
       
      </div>
      </div>
      <div className='footer' id='hire'>
      <div className="navbar1">
        <div>
        <h2 className='home7'>Fation Krasniqi</h2>

        </div>
        <div className="links"><h2 className='home7'>2024 / All rights Reserved</h2>
   
       </div>
       <div>
       <a href="https://www.instagram.com/fationn_/?hl=en" target="_blank">    <img className='footerimage' src={linkedin}></img></a>
      <a href="https://github.com/fationkrasniqi" target="_blank">     <img className='footerimage' src={github}></img> </a>
       <a href="https://www.linkedin.com/in/fation-krasniqi/" target="_blank">   <img className='footerimage' src={instagram}></img></a>
       </div>
      </div>
      
      </div>
    </div>
  );
}

export default App;
